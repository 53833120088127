// WARNING: DO NOT EDIT. This file is automatically generated by CDP Build. It will be overwritten.

export const cdpenv = {
		"TerminalApi":"https://canary-terminal.surecanpay-uat.com/",
	"TennantAPI":"https://canary-tenant.surecanpay-uat.com/",
	"FilesURL":"https://canary-files.surecanpay-uat.com/settlement",

};

export const cdpreadme=`# SureCanPay Integration Guide

1. [Running the Driver](#Running-the-Driver)
   1. [Getting Started](#Getting-Started)
   2. [testEPOS](#testEPOS)
   3. [swaggerui](#swaggerui)
   4. [Windows](#Windows)
   5. [Android](#Android)
2. [Callbacks](#Callbacks)
3. [Driver Configuration](#Driver-Configuration)
4. [Integrating the Driver into your Application](#Integrating-the-Driver-into-your-Application)
   1. [Transactions](#Transactions)
   2. [Settlement and End of Day](#Settlement-and-End-of-Day)
   3. [Receipt Formatting](#Receipt-Formatting)
   4. [Printing Receipts](#Printing-Receipts)
5. [Firmware Updates](#Firmware-Updates)
6. [Security](#Security)
7. [Error Codes](#Error-Codes)

## Running the Driver

A driver is available for Windows, OSX/Linux(MAC), and Android. It can be downloaded from the
[SureCanPay Website](https://www.surecanpay.com) by registering and going to the developers tab.
The driver provides a simple http interface which is defined in a swagger file 'conf.yml'. Options for
running the driver are in [Driver Configuration](#Driver-Configuration).

### Getting Started

To use the driver you will need a PIN pad. SureCanPay provides development PIN pads and production PIN pads. Development PIN pads
can be ordered from the site SureCanPay Website](https://www.surecanpaytest.com). PIN pads are linked to organisations, so a PIN pad ordered
on the development site will not work in production and vica versa. You create your organisation id when you register on the web site. If you have
a team of developers, add them as users to the same organisation (rather than get all the developers to register) as PIN pads are tied to the organisation
and cannot be moved between organisation. When you log into the website, your organisation and userid are shown in the top right next to the LOGOUT button.

1. Plug the PINpad in. It should display "Welcome To SureCanPay".
2. Download the driver approopriate for your Development Operating System. The download is a zip file. The zipfile will contain a bin directory and a
   subdirectory for your chosen OS. In that directory is a file called 'scanpay' (or 'scanpay.exe' for windows). Your driver. There is also a testEPOS program
   for simulating an EPOS till. See below for its use.
3. On the SCP website, log in and navigate to the Manage > Terminals tab. Find the terminal you are using, and click the “Edit” button.

![Swagger ScreenShot](images/edittid.png)

4. Add in test merchant details - a Merchant ID and Merchant Address (For the development site you can enter anything – for production these must be the details for the site where the PIN pad is being deployed). Click submit.
5. You should be back at the Manage > Terminals tab. Click “PIN” next to the terminal you are working with.

![Swagger ScreenShot](images/generatepin.png)

6. Enter the serial number of your terminal (located on the back of the device) and click “Submit”.
7. Your new One Time Pin (OTP) will be generated at the bottom of the page. The pin will only be active for an hour, so make sure to use it within that time frame.
8. While your PIN pad is still on the “Enter Sec Code” screen, enter the pin you just generated on the website and press the green button.
9. Once this is done successfully, the terminal will download the configuration it needs and display the “SureCanPay Ready” screen.
10. Your PIN pad is now ready to perform transactions. You can either perform transactions using the swaggerui (this is useful for undertanding the api) or
    the testEPOS program that comes with the driver.

When the service is started, it will create a database directory "db" in the working directory. If you delete or move the contents of this directory, you will need to re-initialise the device via One Time PIN.

### testEPOS

For testEPOS to communicate with the driver it will need the API key displayed on the Developers tab of the SureCanPay website. When started testEPOS creates
a config.json file. You can either edit this file with a text editor and replace "test-api-key" with the value from the website or there is an option in the tool File>Options where the API key can be changed.

\`\`\`json
{
  "apiKey": "test-api-key",
  "showCustomerReceipt": true,
  "showMerchantReceipt": true,
  "callbackPort": 48080
}
\`\`\`

_Example config.json_

### swaggerUi

To get the driver to display the swaggerui edit the conf.yml file and add the line ui:true as below

\`\`\`yaml
callbackHost: "127.0.0.1:48080"
listenPort: 8080
logging: "verbose"
registrationUrl: "https://reg.surecanpay.com/reg"
ui: true
\`\`\`

When you restart scanpay, you should see the line

\`\`\`
Serving Swagger UI at: http://127.0.0.1:8080/swaggerui/
\`\`\`

If you use a web browser to go to this URL you be able to see the swaggerui as below

![Swagger ScreenShot](images/swagger.png)

Before you can use this you must enter the API key from the SureCanPay website. Click the Authorise Button and enter the key from the website developer tab.
You should see "Authorised". You can now explore the API.

For each method there is a "Try it Out" button that can be used to send a request to the driver and see what the response will be. For example for "GET /"
press "Try it Out" then "Execute". The response should be Code 200 - Successful and give the status of the device and its serial number and terminal ID.

\`\`\`json
{
  "deviceConnected": true,
  "firmwareVersion": "35501000",
  "running": true,
  "serialNo": "2110000065",
  "terminalId": "10000020"
}
\`\`\`

To perform a transaction use "POST /transaction/{type}" and as the type enter "sale". For the Body cut and paste the following:

\`\`\`json
{
  "txnId": "Transaction1",
  "amount": 1000
}
\`\`\`

The PIN pad will display Present Card and the transaction amount. Use one of the cards provided with the PIN pad to complete the transaction. The transaction
should be authorised and its response will contain the receipts that should be printed. If you go to the Transactions tab of the SureCanPay website the
transaction should be displayed.

[Driver Configuration](#Driver-Configuration)

### Configuration File

Provided with the software is a recommended configuration file, however based on your environment, you may need to configure some of these options. Listed below is a breakdown of all options available in conf.yml

\`\`\`
registrationUrl (string)    - The URL of the registration API, if not set will point to production environment
callbackHost (string)       - The URL of the callback API, if your POS system implements it
listenPort (int)            - The port the service listens on for requests from the POS, if not set will default to 8080
ui (bool)                   - If set, will run the swaggerUi interface on port 8081
logging (string)            - Logging level, if not set will default to verbose, options are error, warning, info, debug and verbose
message (string)            - Up to two line message that will be displayed on the screen while idle, separate lines with "\\n"
emvDebugMode (bool)         - Debugging option for more verbose logs, may be requested by SureCanPay engineers, otherwise do not set
emvReceipt (bool)           - Will print additional EMV data on receipts, do not set unless requested by SureCanPay engineers
proxyUrl (string)           - URL for defining network proxies
usbType (string)            - Type of USB connection, if not set will default to libusb, options are libusb, serial and xac (Windows 32-bit software only)
comPort (string)            - COM port to connect to when usbType is set to serial, format is COM0 COM1 etc
\`\`\`

### Windows

You can get the driver to run as a service on windows using the following command line arguments

\`\`\`
-c (install|remove|start|stop|pause|continue)
install     - Configure the driver as a system service
remove      - Remove the driver from system services
start       - Start the service
stop        - Stop the service
pause       - Pause the service
continue    - Resume the service
\`\`\`

If -c is not provided, the service will run in the active cmd context.

Before running the SCP driver, you may need to configure a USB device driver for your PED. This can be done by following the below steps

#### For LibUSB or USB Serial

1. Stop any instances of the SCP driver from running
3. Run zadig-2.5.exe from the "driver" folder
4. At the top of the window, open Options and tick "List all Devices"
5. In the large drop-down box, select "Cortex M1 Platform"
6. Select the appropriate driver for your Operating System
    1. LibUSB 64-bit Windows: libusb-win32
    2. LibUSB 32-bit Windows: libusbK
    3. USB Serial: USB Serial (CDC)
7. Click "Replace Driver" this may take some time to complete
8. Start the SCP driver
    1. If the SCP driver does not connect to your device (WELCOME TO SURECANPAY will no longer be displayed on the screen), you may need to reboot your machine before the USB driver change takes effect.
    
#### For Xac USB (32-bit Software Only)

While only the 32-bit version of the SureCanPay software can communicate with the XacUsb driver, the software should work without issue on 64-bit versions of Windows.

1. Stop any instances of the SCP driver from running
2. Extract the OS-appropriate driver files from the "driver" directory
3. Run "Install.exe" or "Install(x64).exe" and wait for the program to finish
4. Start the SCP driver
    1. If the SCP driver does not connect to your device (WELCOME TO SURECANPAY will no longer be displayed on the screen), you may need to reboot your machine before the USB driver change takes effect.

#### Removing the USB Drivers

If you need to switch the driver your device is using for any reason, you will need to first remove the existing driver, which can be done using the following steps

1. Open Device Manager (Open the start menu and type "Device Manager")
2. Locate the "Cortex M1 Platform" in the list
    1. This will either be under "libusb devices" or "Universal Serial Bus Devices"
3. Right click on the device and select "Uninstall device"
4. If prompted, check "delete driver software" to ensure the device does not automatically pick it up again

### Android

The SureCanPay driver works on any Android device that can connect via USB to the PIN Pad. It also works on EMV approved Integrated Android devices supplied by SureCanPay where card reading and PIN Entry are integrated.

To integrate the driver API into your own application, add the \\*.aar file downloaded from the website to your Android Studio project

\`\`\`
In Android Studio, File -> New -> Module
In the window that opens, select "Import .JAR/.AAR Package" and click "Next"
Browse to the provided *.aar file and select "Finish"
Add the new module as a dependency to your main module
\`\`\`

To start the driver, you will need to implement the included "emv.Connection" interface, this interface requires you to override four methods detailed below

\`\`\`
public void open() throws Exception
In this method, you should establish a connection to the SureCanPay device via USB.
The details for the SureCanPay device are as follows:
VID: 0x2182
PID: 0x8000
If you are unable to create a connection to the device, throw a new Exception with the message "ErrorOpeningDevice"
\`\`\`

\`\`\`
public void close() throws Exception
In this method, you should close any open USB connections to the SureCanPay device
\`\`\`

\`\`\`
public void write(byte[] data) throws Exception
In this method, you should write the provided byte array to the open SureCanPay USB device.
If the device is not open, throw a new Exception with the message "NotOpen"
If there is an error writing to the device, throw a new Exception with the message "ErrorWriting"
\`\`\`

\`\`\`
public byte[] read() throws Exception
In this method, you should wait until bytes become available from the SureCanPay USB device.
Once bytes are available, you should read all available bytes and return them.
- You should read a maximum of 1024 bytes every time this command is called.
- If you do not receive 1024 bytes, do not pad the returned array to 1024 bytes.
If the device is not open, throw a new Exception with the message "NotOpen"
\`\`\`

Once you have implemented the interface, you must initialise the service before you start it. In a new thread, run the following:

\`\`\`
emv.Emv.initConfig(<Full path to conf.yml on the device>);
emv.Emv.initDB(<Full path to a location the driver database can be stored>); // Must have read and write access to this location
emv.Emv.initLog(<Full path to a location the logs can be stored>, (int) <Number of log files to keep>); // Must have read and write access to this location. This command is optional, if you do not run it, log files will not be created.
emv.Emv.initUSB(<An instance of your implementation of the emv.Connection interface>, (byte) 0x00); // Byte detailed below
emv.Emv.listen(); // Blocking call
\`\`\`

Format for the byte in initUSB()

\`\`\`
bit 8-6: RFU
bit 5: PC Functionality - Set to 0
bit 4-3: RFU
bit 1:
    0 - Device has physical or external PIN pad (i.e. Usb connection to RP-10)
    1 - Device uses touchscreen PIN entry - Callback service required
\`\`\`

Due to an issue with the tool we are using to compile our code to a native android library, we are not able to determine the timezone of the device. When you start the service, and any time the timezone has changed during operation (For example, due to daylight savings) you should run the following command:

\`\`\`
emv.Emv.setTimezone(<Shorthand timezone as a string, e.g. "GMT" or "BST">)
\`\`\`

The driver should now be running and logcat entries should be available.

## Callbacks

The SureCanPay API provides two optional callbacks, /message and /request. The message callback provide a message that can be displayed to an operator
during a transaction before it has completed. For example if a card requiring PIN Entry is presented the message will indicate that the customer should be
entering a PIN. The /request call back is required when using Integrated devices, to initiate processes like One Time PIN and PIN entry.
If you are using the callback service, you should implement two endpoints, /message and /request, detailed below

- /message
  - The body in this request will contain a json object with a string field "message"
  - This field will contain a message that should be displayed to the customer if it is not already on the device's screen.
  - This field may also be useful to show to the cashier to inform them of the state of the device
- /request
  - The body in this request will contain a json object with the string fields "valueName", "valueType" and an array of strings "valueOptions"
  - You should respond to these requests with a HTTP 200, containing a json object in the body with a string field of "value"
  - If you are unable to respond to the request (i.e. you are waiting for user feedback on a different thread) you should respond with an empty string as the value. The service will start polling every second until it receives a non-empty value.
  - If you are using a device that uses touchscreen PIN entry, you will need to implement the following requests, based on the "valueName" field
    - ENTER SEC CODE
      - This will be requested the first time the service is run
      - You should obtain your one time PIN code from the SureCanPay website and enter that into the value of the response
      - This value will be different for every device, and expires a short while after it is generated. You should provide the user a method of entering this value on device setup.
    - CbSelectApp
      - This will be requested when the card has multiple applications the cardholder can choose from
      - You should present the options to the cardholder and allow them to select one of them
      - Return the index of the selected option in a single byte, printed as hex (i.e. index 0 = '00', index 1 = '01', index 10 = '0A')
    - CbPinEntry
      - This will be requested when PIN entry is required by the card
      - You should respond to this with the layout of your PIN entry buttons on the device screen in the following format
        - {Kx}{Ky}{Kw}{Kh}{Fx}{Fy}{Fw}{Fh}
          - Kx, Ky -> left-top of PIN pad
          - Kw -> width of four row PIN pad, must be multiple of 3
          - Kh -> height of four row PIN pad, must be multiple of 4
          - Fx, Fy -> left-top of command buttons
          - Fw -> width of command buttons, must be multiple of 3
          - Fh -> height of command buttons
        - Each value should be printed as four decimal characters with leading 0s
      - Your PIN pad should be laid out in the following format
        - 1 2 3
        - 4 5 6
        - 7 8 9
        - \\* 0 #
      - The command buttons should be laid out in the following format
        - Red/Cancel Yellow/Back Green/Confirm
      - The PIN pad and command buttons do not have to be next to each other, but must not overlap
      * After responding to this command, the SAIO library command 'Sys.setPinEntryModeEnabled(true);' should be called
  - CbClosePinEntry
      - This will be sent when the PIN pad should be closed
      - Respond to this request with "true" once the PIN pad has been removed from the device screen
      - After responding to this command, the SAIO library command 'Sys.setPinEntryModeEnabled(false);' should be called
  - For all devices, the following requests must be implemented, based on the "valueName" field
    - RFU

## Driver Configuration

The provided conf.yml must be available on your device.

> For Windows, OSX and Linux, this must be in the same directory as the executable

> For Android, you provide the location of conf.yml in the above section

The configuration file allows you to define several settings of the driver, detailed below. This file must be valid [YAML](https://en.wikipedia.org/wiki/YAML)

\`\`\`
registrationUrl: String. Optional. The address for the SureCanPay registration service.
    Needs to be included if you are connecting to the test environment, not required if connecting to production.
    If this value is changed to point at a different environment, you will need to delete the /db/ folder
callbackHost: String. Optional. The address for the callback service, if it exists.
    Use callback IP of 127.0.0.1 instead of localhost.
listenPort: Integer. Default 8080. The port that the driver api service listens on.
ui: Boolean. Default false. Runs an interactive webpage allowing debug of all API calls.
    Webpage URL will be printed in service log
\`\`\`

## Integrating the Driver into your Application

Communication to the driver is done over http requests. Provided with the software is a pair of [YAML](https://en.wikipedia.org/wiki/YAML) files, that can be used to generate client and server code through the tools found at [Swagger.io](https://swagger.io/tools/swagger-codegen).

If your language is not supported officially by swagger, there may be a third party tool available. You may also use the YAML file as a reference, and call the endpoints via HTTP.

Once you have the codegen set up, you should generate a client from 'scpay.yml'. Optionally, you may generate a server from 'callback.yml', though this is optional - when configured, the driver will send display messages to the callback service for display on the POS.

When generated, you will have access to the driver API endpoints through the operations that have been generated, along with a model that matches the specification.

In order to call the service, you will need to provide your API key in the header of the request, under the key of 'X-API-Key', the generated code should have a method of including this. The API key can be found on the SureCanPay website under the developers tab.

### Transactions

To start a transaction through the driver, use the '/transaction' endpoint of the API. The only required fields for this object are Transaction ID and Amount. The transaction ID should be unique.

> Currently supported transaction types are: sale, refund, reversal and eod
> Amounts must be provided as a whole number, in pence

Once a transaction is initiated, the driver will respond in under two minutes with a transaction result. Contained in this result is an Error field, if this field is not empty, the transaction has failed, error codes are detailed in [Error Codes](#Error-Codes)

The transaction response also contains a 'result' field, this field contains response information from the acquirer, along with flags detailing additional CVM that must be completed. At the time of writing, the only flag provided is 'signatureRequired', if this flag is set true, you must ask the customer to sign the receipt and prompt the cashier to verify the signature. If the signature is verified, nothing more needs to be done. If the signature is not accepted, you must reverse the transaction by immediately starting a new transaction with the type of 'reversal', this will void the most recent transaction.

Receipt fields are also included in the transaction response, these fields are labeled for both Customer and Merchant. The receipt contains all of the required information for yourselves and the cardholder. If a signature is required then the merchant receipt must be printed, signed and then retained for signature verification.

When you start a transaction you must assume it is successful until you receive a response. You can cancel a transaction, by using the cancel method, but if the transaction has been authorised it may return successful even if cancelled. You can reverse the last transaction by sending a reversal transaction. If for example you start a transaction then your application is closed down before receiving a response, on restart you must reverse the transaction in case it was completed.

As transactions are performed they can be viewed on the SureCanPay website.

#### Offline Authorisations

When starting a transaction, you may include an additional flag for offline authorisations. If this flag is set to true, the terminal will perform no online authorisation, and will approve the transaction after any offline verification steps by the card.

Please note that the following transactions are not currently supported for offline authorisations and will be rejected by the terminal:

- Transactions with a Cashback amount
- Magstripe card reads, including fallback transactions
- Online-only cards
- American Express cards
- Card not Present transactions (Not currently supported by SCP)

Also note that the following scenarios are not permitted for offline authorisations, this is not able to be automatically detected by the terminal, therefore the POS developer must put in methods to ensure offline authorisations are not supported in the following circumstances:

- Gaming / Gambling
- Cardholder Activity Terminals (Self-checkout, Parking Meters etc)
- Automated Fuel Dispensers

### Settlement and End of Day

SureCanPay settles transactions with acquiring banks on a daily basis at a time set by the bank. To aid reconciliation of transactions against settlement SureCanPay provides two options for retailers to close their business day.

1. Flexible end of day: The driver accecpts an "eod" transaction. This will close a batch of transactions as ready for settlement, any transactions performed after the eod message has been sent will not be settled until another eod message is sent.
2. Fixed cut off: A fixed cut off time can be set eg. 24:00. Any transactions performed after this time will not be settled until the next day.

To aid reconcilliation settlement files containing all transaction references and available to be downloaded from the SureCanPay website. Settlement file download can be automated using an Access Key generated on the site.

### Receipt Formatting

When starting a transaction, there is an optional array field 'receiptFormat', you may add entries to this array, which will then be added on to the receipt generated by the driver.

Within these receipt lines, you may include formatting directives, which may be used to contain either transactional data, or fields from the provided 'eposData' in the transaction request.

Format: \`<Field>[Format]</Field>\`

Available Fields:

    TxnID
    Amount
    TipAmount
    CashbackAmount
    ResponseCode
    ResponseText
    RRN
    AuthCode
    SignatureRequired
    MediaType
    Pan
    Expiry
    Issuer
    Token
    MerchantID
    TerminalID

Format (optional) must be valid golang format (gobyexample.com/string-formatting)

EposData Format: \`<EposData:Field[:Index]>[Format]</EposData>\`

- Field must be present in EposData object passed in Transaction Request
- Index (optional) is zero-based for array values
- Sub-arrays and sub-objects not supported at this time

### Printing Receipts

In the transaction response, there will be two lists of ReceiptLine objects, if populated, these receipts must be offered to the customer in either physical or digital format.

_If the transaction requires signature verification, the receipts must be provided, Signature verification must be performed on the Merchant copy which should then be retained_

To aid with the formatting of receipts, below is a disambiguation of the values found in each receipt line

- TextLeft
  - Should be printed left-aligned on the receipt
- TextCenter
  - Should be printed center-aligned on the receipt
- TextRight
  - Should be printed right-aligned on the receipt
  - If this would overlap with TextLeft, it should be shifted one line down
- FontBold
  - If available, this line should be printed in bold characters
- FontItalic
  - If available, this line should be printed in italic characters
- FontUnderline
  - If available, this line should be printed with an underline
- FontReverse
  - This line uses a character set that is printed right to left (e.g. Arabic text)
- FontSize
  - The font size that should be used to print this line of text
  - If your printer can only differentiate between normal and double size text, print all sizes 25 or above as double size
- Encoding
  - If this field is blank, it can be ignored
  - When the field is not blank, you should only print the line if the encoding matches your printers capabilities
  - If your printer does not support utf-8 characters (Cyrillic, Chinese etc) you should only print lines with encoding of "ascii" or no encoding specified
  - If your printer does support utf-8 characters, you should only print lines with encoding "utf-8" or no encoding specified

## Firmware Updates

SureCanPay may release device firmware updates to implement new features and resolve issues with the devices. You will be notified of any firmware updates for your device and the steps, if any, you need to take to install them.

To run the firmware update, the '/updateFirmware' endpoint should be called, this will check the SureCanPay servers for available updates and download/install them. 

This process is also configured to run automatically during defined out-of-hour periods, provided the SCP software is running at that time.
 
This process may take up to half an hour to complete. If this process fails, or takes longer than half an hour, please get in touch with SureCanPay support.

## Security

The SureCanPay system uses Amazon Web Services to provide high througput, high velocity, secure transaction processing. To ensure that card data is protected, PCI P2PE key management is used to ensure that card data is encrypted using keys generated in secure hardware security modules. Card data is encrypted in the PIN pad and only unencrypted when sent to the bank for authorisation and clearing.
Card details are never exposed to retailers systems.

## Error Codes

As a response to API calls, you may receive a SureCanPay internal error code. Please note that this is separate to any HTTP code you will have received.

Below is a list of the codes, their meaning and if available, follow-up steps you should take.

1. Device Errors
   - 100 - One Time PIN has not been performed
   - 101 - Configuration could not be downloaded, contact SureCanPay for support
   - 102 - Configuration is invalid, contact SureCanPay for support
   - 103 - Key Injection failed, contact SureCanPay for support
   - 104 - Key Configuration failed, contact SureCanPay for support
   - 110 - Device is not connected
   - 111 - Device has encountered an unknown error during boot, contact SureCanPay for support
   - 190 - Incorrect device is connected. After OTP has been performed, you must delete the driver's DB files before attempting to configure a different terminal on this machine
   - 199 - Unknown Error, contact SureCanPay for support
1. Transaction Errors
   - 200 - Transaction cannot start. Ensure previous transaction has finished
   - 201 - Transaction type not supported
   - 202 - Invalid Amount, must be zero or higher
   - 203 - Card not accepted - A card not processed by the acquirers available
   - 204 - The presented card has expired
   - 205 - The presented card does not support this transaction
   - 210 - The transaction took too long to complete
   - 299 - Unknown Error, contact SureCanPay for support
1. Cancel Transaction Errors
   - 300 - Cannot cancel the transaction at this time. Perform a void operation once the transaction completes.
   - 301 - No Transaction to cancel
   - 399 - Unknown Error, contact SureCanPay for support
1. Void Transaction Errors
   - 401 - No Previous Transaction in Batch
   - 402 - Previous transaction is a Void or does not require Voiding
   - 499 - Unknown Error, contact SureCanPay for support
1. Eod Errors
   - 500 - Cannot start an End of Day at this time. Wait for the current operation to complete
   - 510 - End of Day took too long to complete
1. Firmware Update Errors
   - 600 - Firmware failed to update. Contact SureCanPay support
   - 610 - Firmware took too long to update. Contact SureCanPay support
`

